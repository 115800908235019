import React, {useContext, useEffect} from 'react';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import styles from "./shipping.module.css";
import Layout, {designSystem} from "../components/layout";
import SEO from "../components/seo";
import headerStyles from "../components/header.module.css";
import Header from "../components/header";
import {Link} from "gatsby";
import SelectedProductContext from "../context/selectedProductsContext";

const Shipping = ({location}) => {
    const selectedProducts = useContext(SelectedProductContext);

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    let stripe;

    useEffect(() => {
        if (typeof window !== `undefined`) {
            stripe = window.Stripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);
        }
    }, [])

    const getItemsToSend = () => {
        const arrayOfProductsToSend = selectedProducts.selectedProducts.map(product => {
            return {
                sku: product.id,
                quantity: parseInt(product.quantity)
            };
        })

        arrayOfProductsToSend.push({
            sku: location.state.shipping.sku,
            quantity: 1
        })

        return arrayOfProductsToSend;
    }

    async function redirectToCheckout(email) {
        const {error} = await stripe.redirectToCheckout({
            items: getItemsToSend(),
            successUrl: `${process.env.GATSBY_HOST}/success`,
            cancelUrl: `${process.env.GATSBY_HOST}/shop`,
            billingAddressCollection: 'required',
            customerEmail: email
        })

        if (error) {
            console.warn("Error:", error)
        }
    }

    return (
        <Layout>
            <SEO title="Shipping"/>
            <div
                className={styles.allPage}
            >
                <div
                    className={`${headerStyles.header}`}
                >
                    <Header
                        headerTextColour={designSystem.palette.black}
                        backgroundColour={designSystem.palette.white}
                        backgroundColourSmallScreen={designSystem.palette.white}
                        showSmallHeading={true}
                    />
                </div>
                <div
                    className={styles.content}>
                    <Formik
                        initialValues={{
                            name: '',
                            email: '',
                            message: '',
                        }}
                        onSubmit={
                            (values, actions) => {
                                fetch("/", {
                                    method: "POST",
                                    headers: {"Content-Type": "application/x-www-form-urlencoded"},
                                    body: encode({"form-name": "shipping-details", ...values})
                                })
                                    .then(() => {
                                        redirectToCheckout(values.email);
                                        actions.resetForm();
                                    })
                                    .catch(() => {
                                        alert('Error');
                                    })
                                    .finally(() => {
                                        actions.setSubmitting(false);
                                        selectedProducts.setSelectedProducts([]);
                                    })
                            }
                        }
                        validate={values => {
                            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                            const mobileRegex = /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/;
                            const countryRegex = /australia/i;
                            const errors = {};

                            if (!values.name) {
                                errors.name = 'Name Required'
                            }

                            if (!values.email || !emailRegex.test(values.email)) {
                                errors.email = 'Valid Email Required'
                            }

                            if (!values.phone || !mobileRegex.test(values.phone)) {
                                errors.phone = 'Valid Phone Number Required'
                            }

                            if (!values.street) {
                                errors.street = 'Street Required'
                            }

                            if (!values.city) {
                                errors.city = 'City Required'
                            }

                            if (!values.state) {
                                errors.state = 'State Required'
                            }

                            if (!values.postCode) {
                                errors.postCode = 'Post Code Required'
                            }

                            if (!values.country) {
                                errors.country = 'Country Required'
                            }

                            if (!countryRegex.test(values.country)) {
                                errors.country = 'We currently only ship to Australia through our online store, ' +
                                    'however, visit our contact page and get in touch if you live elsewhere and want' +
                                    ' us to send you some products.'
                            }

                            return errors;
                        }}
                    >
                        {({isSubmitting}) => (
                            <Form name="shipping-details" data-netlify={true}>
                                <h2
                                    className={styles.formHeading}
                                >
                                    Shipping Details
                                </h2>

                                <h3
                                    id={'contactDetailsHeading'}
                                    className={styles.contactDetailsHeading}>
                                    Contact Details
                                </h3>
                                <div
                                    role={'group'}
                                    aria-labelledby={'contactDetailsHeading'}
                                    className={styles.contactDetailsContent}
                                >
                                    <div
                                        className={styles.labelAndInput}
                                    >
                                        <label htmlFor="name">Full Name </label>
                                        <Field name="name"/>
                                        <ErrorMessage
                                            className={styles.errorMessage}
                                            component='span'
                                            name="name"/>
                                    </div>

                                    <div
                                        className={styles.labelAndInput}
                                    >
                                        <label htmlFor="email">Email Address </label>
                                        <Field name="email"/>
                                        <ErrorMessage
                                            component='span'
                                            className={styles.errorMessage}
                                            name="email"/>
                                    </div>

                                    <div
                                        className={styles.labelAndInput}
                                    >
                                        <label htmlFor="phone">Phone Number </label>
                                        <Field name="phone"/>
                                        <ErrorMessage
                                            component='span'
                                            className={styles.errorMessage}
                                            name="phone"
                                        />
                                    </div>
                                </div>

                                <h3
                                    id={'addressHeading'}
                                    className={styles.addressHeading}>
                                    Address
                                </h3>
                                <div
                                    role={'group'}
                                    aria-labelledby={'addressHeading'}
                                    className={styles.addressContent}
                                >
                                    <div
                                        className={styles.labelAndInput}
                                    >
                                        <label htmlFor="street">Street </label>
                                        <Field className={styles.widerInput} name="street"/>
                                        <ErrorMessage
                                            component='span'
                                            className={styles.errorMessage}
                                            name="street"/>
                                    </div>
                                    <div
                                        className={styles.adjacentInputs}
                                    >
                                        <div
                                            className={styles.labelAndInput}
                                        >
                                            <label htmlFor="city">City </label>
                                            <Field name="city"/>
                                            <ErrorMessage
                                                component='span'
                                                className={styles.errorMessage}
                                                name="city"/>
                                        </div>
                                        <div
                                            className={styles.labelAndInput}
                                        >
                                            <label htmlFor="state">State </label>
                                            <Field name="state"/>
                                            <ErrorMessage
                                                component='span'
                                                className={styles.errorMessage}
                                                name="state"/>
                                        </div>
                                    </div>
                                    <div
                                        className={styles.adjacentInputs}
                                    >
                                        <div
                                            className={styles.labelAndInput}
                                        >
                                            <label htmlFor="postCode">Post Code </label>
                                            <Field name="postCode"/>
                                            <ErrorMessage
                                                component='span'
                                                className={styles.errorMessage}
                                                name="postCode"/>
                                        </div>
                                        <div
                                            className={styles.labelAndInput}
                                        >
                                            <label htmlFor="country">Country </label>
                                            <Field name="country"/>
                                            <ErrorMessage
                                                component='span'
                                                className={styles.errorMessage}
                                                name="country"/>
                                        </div>
                                    </div>
                                </div>

                                <h3
                                    id={'additionalDetailsHeading'}
                                    className={styles.additionalDetailsHeading}>
                                    Additional Details
                                </h3>
                                <div
                                    role={'group'}
                                    aria-labelledby={'additionalDetailsHeading'}
                                    className={styles.additionalDetailsContent}
                                >
                                    <div
                                        className={styles.labelAndInput}
                                    >
                                        <label htmlFor="message">Message </label>
                                        <Field name="message" component="textarea"/>
                                        <ErrorMessage
                                            component='span'
                                            className={styles.errorMessage}
                                            name="message"/>
                                    </div>
                                </div>

                                <section
                                    className={styles.submitButton}
                                >
                                    <Link to={'cart'}>Cancel</Link>
                                    <button disabled={isSubmitting} type="submit">Checkout</button>
                                </section>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Layout>
    )
}

export default Shipping;



